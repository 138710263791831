import React from 'react';
import { Robot } from './feature/robot/robot';
import './App.css';

const App = () => {
  return (
    <Robot />
  );
};

export default App;